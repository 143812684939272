import Plotly from "plotly.js-dist";
import * as plotlyLocales from "plotly.js-locales";

// Register the current locale with Plotly
function registerCurrentLocale() {
  if (window.locale in plotlyLocales) {
    Plotly.register(plotlyLocales[window.locale]);
  }
}

const colorPalette = {
  palette: [
    "#E25F51",
    "#F26091",
    "#BB65CA",
    // "#9572CF",
    "#7884CD",
    "#5B95F9",
    // "#48C2F9",
    "#45D0E2",
    "#48B6AC",
    "#52BC89",
    "#9BCE5F",
    // "#D4E34A",
    "#FEDA10",
    // "#F7C000",
    "#FFA800",
    "#FF8A60",
    // "#C2C2C2",
    // "#8FA4AF",
    // "#A2887E",
    // "#A3A3A3",
    "#AFB5E2",
    "#B39BDD",
    // "#C2C2C2",
    "#7CDEEB",
    // "#BCAAA4",
    "#ADD67D",
  ],
  mappings: {
    device_type: {
      printer: 1,
      display: 2,
      computer: 5,
      laptop: 6,
      smartphone: 11,
      tablet: 10,
      audiovisual: 12,
      peripheral: 8,
      component: 9,
      infrastructure: 10,
      other: 0,
    },
  },
  pick(str, map = null) {
    if (map) {
      return this.palette[this.mappings[map][str]];
    } else {
      return this.toColor(str);
    }
  },
  toColor(str) {
    let hash = 0;

    // If the string is empty, return 0
    if (str.length === 0) return hash;

    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char; // hash * 31 + char
      hash = hash & hash; // Convert to 32bit integer
    }

    // To make the hash positive, we can use Math.abs (optional)
    return this.palette[Math.abs(hash) % this.palette.length];
  },
};

// Define a rgb color palette for the different device types
const colors = [
  "231,107,243",
  "127,128,255",
  "0,139,255",
  "0,117,113",
  "255,102,195",
  "255,127,146",
  "255,168,107",
  "255,210,92",
  "249,248,113",
];

// Darken the color for the error bars
function darkenColor(rgbString, percentage) {
  return rgbString
    .split(",")
    .map((c) => c * percentage)
    .join(",");
}

// Generate the default plot layout
function generatePlotLayout(title = null, xForecast = null) {
  const plotLayout = {
    paper_bgcolor: "rgb(255,255,255)",
    font: { size: "12", color: "rgb(107 114 128)" },
    margin: { l: 32, r: 32, t: 32, b: 36 },
    legend: {
      x: 0.05,
      y: 0.95,
      xanchor: "left",
      yanchor: "top",
      itemclick: false,
      itemdoubleclick: false,
    },
    modebar: {
      remove: [
        "zoomout",
        "zoomin",
        "zoom",
        "pan",
        "autoscale",
        "lasso",
        "select",
        "resetscale",
        "toimage",
      ],
    },
    dragmode: false,
    // Start the y-axis from the origin always
    yaxis: {
      autorange: "min",
      autorangeoptions: { minallowed: 0 },
      automargin: true,
    },
  };

  if (title !== null) {
    plotLayout.title = {
      text: title,
      font: { size: 18 },
      x: 0,
      xanchor: "left",
      pad: { l: 32, r: 32 },
    };
  }

  if (xForecast !== null) {
    plotLayout.xaxis = {
      range: [xForecast[0], xForecast[xForecast.length - 1]],
    };
  }

  return plotLayout;
}

function setDateXAxis(plot, t0, xlabels) {
  const override = {
    layout: {
      xaxis: {
        tick0: t0,
        dtick: "M1",
        showticklabels: false,
      },
      annotations: xlabels.map((label) => {
        return {
          x: label[0],
          xanchor: "center",
          y: 0,
          yref: "paper",
          yanchor: "top",
          text: label[1],
          showarrow: false,
        };
      }),
    },
  };
  deepMergeOptions(plot, override);
}

function addTodayLine(plot, todayString) {
  const today = new Date().setHours(0, 0, 0, 0);
  const todayLine = {
    layout: {
      shapes: [
        {
          type: "line",
          x0: today,
          x1: today,
          y0: 0,
          y1: 1,
          yref: "paper",
          line: { color: "black", width: 1, dash: "none" },
        },
      ],
      annotations: [
        {
          x: today,
          y: 1,
          yref: "paper",
          text: todayString,
          yanchor: "bottom",
          xanchor: "center",
          showarrow: false,
        },
      ],
    },
  };
  deepMergeOptions(plot, todayLine);
}

function featherTraces(y0, currentMonth, forecast) {
  if (currentMonth === undefined || forecast === undefined) {
    return {
      mean: [y0],
      quantiles: { 0.025: [y0], 0.16: [y0], 0.84: [y0], 0.975: [y0] },
    };
  }

  return {
    mean: [y0].concat(currentMonth.mean).concat(forecast.mean),
    quantiles: {
      0.025: [y0]
        .concat(currentMonth.quantiles["0.025"])
        .concat(forecast.quantiles["0.025"]),
      0.16: [y0]
        .concat(currentMonth.quantiles["0.16"])
        .concat(forecast.quantiles["0.16"]),
      0.84: [y0]
        .concat(currentMonth.quantiles["0.84"])
        .concat(forecast.quantiles["0.84"]),
      0.975: [y0]
        .concat(currentMonth.quantiles["0.975"])
        .concat(forecast.quantiles["0.975"]),
    },
  };
}

function addFeatherTrace(plot, ydata, base) {
  addSingleFeather(plot, ydata, base, "0.025", "0.975");
  addSingleFeather(plot, ydata, base, "0.16", "0.84");

  // add central line
  plot.data.push(
    deepMergeOptions(structuredClone(base), {
      y: ydata.mean,
      hoverinfo: "x+y",
    }),
  );
}

function addSingleFeather(plot, ydata, base, q1, q2) {
  plot.data.push(
    deepMergeOptions(structuredClone(base), {
      y: ydata.quantiles[q1],
      line: { width: 0 },
      hoverinfo: "skip",
      showlegend: false,
    }),
  );

  plot.data.push(
    deepMergeOptions(structuredClone(base), {
      y: ydata.quantiles[q2],
      line: { width: 0 },
      hoverinfo: "skip",
      showlegend: false,
      fill: "tonexty",
      fillcolor: base.line.color + "60",
    }),
  );
}

function addBaseToFeatherTraces(base, traces) {
  traces.mean = traces.mean.map((v, i) => {
    return v + base[i];
  });
  for (const q of ["0.025", "0.16", "0.84", "0.975"]) {
    traces.quantiles[q] = traces.quantiles[q].map((v, i) => {
      return v + base[i];
    });
  }
  return traces.mean;
}

// Generate the default plot configuration
function generatePlotConfig() {
  return {
    responsive: true,
    scrollZoom: false,
    locale: window.locale,
    displaylogo: false,
    showAxisDragHandles: false,
    doubleClick: false,
  };
}

// Generate the x-axis labels for the forecast
function generateXForecast(input) {
  return Array.from({ length: input.mean.length }, function (_, i) {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + i + 2, 0);
  });
}

function deepMergeOptions(standard, override) {
  for (const [k, v] of Object.entries(override)) {
    if (!Object.prototype.hasOwnProperty.call(standard, k)) {
      standard[k] = v;
    } else if (Array.isArray(v)) {
      standard[k] = standard[k].concat(v);
    } else if (typeof v === "object" && v !== null) {
      standard[k] = deepMergeOptions(standard[k], v);
    } else {
      standard[k] = v;
    }
  }
  return standard;
}

function commonPlotOptions() {
  return {
    paper_bgcolor: "rgba(128, 128, 128, 0.1)",
    plot_bgcolor: "transparent",
    xaxis: { gridcolor: "rgba(128, 128, 128, 0.2)" },
    yaxis: { gridcolor: "rgba(128, 128, 128, 0.2)" },
    margin: { l: 40, r: 40, t: 60, b: 40 },
  };
}

function drawPlot(el, callback, data, overrides = {}, I18n = {}) {
  const defaultPlot = {
    layout: generatePlotLayout(),
    config: generatePlotConfig(),
    data: [],
  };
  const plot = deepMergeOptions(defaultPlot, overrides);

  // call the given function with the proper parameters
  const postCallback = callback(plot, data, I18n);

  Plotly.newPlot(el, plot);

  if (postCallback) {
    postCallback(el);
  }

  return true;
}
window.drawPlot = drawPlot;

function devicesByDestinationOverTime(plot, data, I18n) {
  setDateXAxis(plot, data.x.history[0], data.xlabels);

  let stackBase = new Array(data.x.forecast.length + 2).fill(0);

  for (const key of ["available", "locations", "employees"]) {
    const k = "devices.count." + key;
    const v = data.y[k];

    plot.data.push({
      x: data.x.history,
      y: v.history.mean,
      stackgroup: "history",
      fillcolor: colorPalette.pick(k) + "B0",
      line: { color: colorPalette.pick(k), width: 5 },
      showlegend: true,
      name: I18n[key],
      hoverinfo: "x+y",
    });

    plot.data.push({
      x: [data.x.history.at(-1), data.x.rolling_current_month_forecast].concat(
        data.x.forecast,
      ),
      y: [v.history.mean.at(-1), v.rolling_current_month_forecast.mean].concat(
        v.forecast.mean,
      ),
      stackgroup: "forecast",
      line: { width: 0 },
      fillcolor: colorPalette.pick(k) + "60",
      showlegend: false,
      hoverinfo: "skip",
    });

    const featherY = featherTraces(
      v.history.mean.at(-1),
      v.rolling_current_month_forecast,
      v.forecast,
    );
    stackBase = addBaseToFeatherTraces(stackBase, featherY);

    addFeatherTrace(plot, featherY, {
      x: [data.x.history.at(-1), data.x.rolling_current_month_forecast].concat(
        data.x.forecast,
      ),
      line: {
        color: colorPalette.pick(k),
        dash: "dash",
        width: 5,
      },
      showlegend: false,
      mode: "lines",
      fillpattern: {
        shape: "/",
        fgcolor: colorPalette.pick(k) + "A0",
      },
    });
  }

  deepMergeOptions(plot.layout, commonPlotOptions());

  addTodayLine(plot, I18n.today);
}
window.devicesByDestinationOverTime = devicesByDestinationOverTime;

function devicesFlowOverTime(plot, data, I18n) {
  setDateXAxis(plot, data.x.history[0], data.xlabels);

  for (const key of ["laptop", "smartphone", "other"]) {
    const k = "devices.in." + key;
    const v = data.y[k];

    plot.data.push({
      x: data.x.history,
      y: v.history.mean,
      line: { color: colorPalette.pick(k, "device_type"), width: 5 },
      showlegend: true,
      hoverinfo: "x+y",
      name: I18n[key],
      mode: "lines",
    });

    addFeatherTrace(
      plot,
      featherTraces(
        v.history.mean.at(-1),
        v.rolling_current_month_forecast,
        v.forecast,
      ),
      {
        x: [
          data.x.history.at(-1),
          data.x.rolling_current_month_forecast,
        ].concat(data.x.forecast),
        line: {
          color: colorPalette.pick(k, "device_type"),
          dash: "dash",
          width: 5,
        },
        mode: "lines",
        showlegend: false,
      },
    );
  }

  deepMergeOptions(plot.layout, commonPlotOptions());

  addTodayLine(plot, I18n.today);
}
window.devicesFlowOverTime = devicesFlowOverTime;

function devicesFlowDashboard(plot, data, I18n) {
  for (const key of ["laptop", "smartphone", "other"]) {
    const k = "devices.in." + key;

    plot.data.push({
      type: "bar",
      x: data.x,
      y: data.y[k],
      marker: { color: colorPalette.pick(key, "device_type"), width: 5 },
      showlegend: true,
      hovertemplate: "%{value:.1f}",
      name: I18n[key],
    });
  }
  deepMergeOptions(plot.layout, {
    paper_bgcolor: "transparent",
    plot_bgcolor: "transparent",
    yaxis: { gridcolor: "rgb(107 114 128)" },
    barmode: "stack",
    margin: { l: 0, r: 0, t: 0, b: 0 },
    legend: {
      orientation: "h",
      xref: "paper",
      yref: "paper",
      yanchor: "top",
      xanchor: "left",
      y: -0.1,
      x: 0,
    },
  });

  return (el) => {
    el.on("plotly_click", () => {
      window.location = data.destination;
    });

    el.querySelectorAll(".xy").forEach((g) => {
      g.style.cursor = "pointer";
    });
  };
}
window.devicesFlowDashboard = devicesFlowDashboard;

function devicesSunburst(plot, data, I18n) {
  plot.data = [
    {
      type: "sunburst",
      ids: data[0],
      labels: data[1],
      values: data[2],
      parents: data[3],
      marker: {
        colors: data[4]?.map((type) => colorPalette.pick(type, "device_type")),
      },
      branchvalues: "total",
    },
  ];
  deepMergeOptions(plot.layout, {
    paper_bgcolor: "rgba(128, 128, 128, 0.1)",
  });
}
window.devicesSunburst = devicesSunburst;

function devicesDonut(plot, data, I18n) {
  plot.data = [
    {
      type: "pie",
      hole: 0.5,
      labels: data.data[1],
      values: data.data[2],
      texttemplate: "%{percent:.1%}",
      marker: {
        colors: data.data[0].map((type) =>
          colorPalette.pick(type, "device_type"),
        ),
      },
      automargin: true,
    },
  ];
  deepMergeOptions(plot.layout, {
    paper_bgcolor: "transparent",
    margin: { l: 0, r: 0, t: 0, b: 20 },
    showlegend: data.data[0].length <= 5,
    legend: {
      orientation: "h",
      xref: "paper",
      yref: "container",
      yanchor: "bottom",
      xanchor: "left",
      y: 0,
      x: 0,
    },
    annotations: [
      {
        showarrow: false,
        text: data.icon,
        x: 0.5,
        y: 0.5,
        font: { size: 40, family: "HeroIcons", color: "rgb(107 114 128)" },
      },
    ],
  });

  return (el) => {
    el.on("plotly_click", () => {
      window.location = data.destination;
    });

    el.querySelectorAll(".slice").forEach((g) => {
      g.style.cursor = "pointer";
    });
  };
}
window.devicesDonut = devicesDonut;

function employeeCountForecastPlot(
  forecastedEmployeeCount,
  historicalEmployeeCount,
  el,
  I18n,
) {
  // Register the current locale
  registerCurrentLocale();

  // Prepare the plot data
  const latestRecordDate = Object.keys(historicalEmployeeCount)[
    Object.keys(historicalEmployeeCount).length - 1
  ];
  const latestRecordCount = historicalEmployeeCount[latestRecordDate];
  const initialDate = [new Date(latestRecordDate)];
  const forecastDates = generateXForecast(forecastedEmployeeCount);

  // Prepare forecast data
  const xForecast = initialDate.concat(forecastDates);
  const yForecast = [latestRecordCount].concat(forecastedEmployeeCount.mean);

  // Prepare historical data
  const xHistorical = Object.keys(historicalEmployeeCount);
  const yHistorical = Object.values(historicalEmployeeCount);

  // Ensure quantiles are sorted from low to high to ensure plot is rendered correctly
  const sortedQuantiles = Object.keys(forecastedEmployeeCount.quantiles)
    .sort()
    .reduce(function (obj, key) {
      // Use concat to merge latestRecordCount with the array from forecastedEmployeeCount.quantiles[key]
      obj[key] = [latestRecordCount].concat(
        forecastedEmployeeCount.quantiles[key],
      );
      return obj;
    }, {});

  // Prepare plot traces
  const baseQuantileTraceConfig = {
    x: xForecast,
    mode: "lines",
    line: { color: "transparent" },
    showlegend: false,
    type: "scatter",
    hoverinfo: "skip",
  };

  const lowerQuantileOuterTrace = Object.assign({}, baseQuantileTraceConfig, {
    y: Object.values(sortedQuantiles)[0],
  });

  const lowerQuantileInnerTrace = Object.assign({}, baseQuantileTraceConfig, {
    y: Object.values(sortedQuantiles)[1],
    fill: "tonexty",
    fillcolor: `rgba(${colors[0]},0.2)`,
  });

  const meanTrace = {
    x: xForecast,
    y: yForecast,
    mode: "lines+markers",
    line: { color: `rgb(${colors[0]})` },
    marker: { color: `rgb(${colors[0]})`, size: 4 },
    fill: "tonexty",
    fillcolor: `rgba(${colors[0]},0.4)`,
    // i18n-tasks-use t('forecast_reports.employees_forecast.forecasted_employee_count')
    name: I18n.forecasted_employee_count,
    type: "scatter",
    hoverinfo: "x+y",
  };

  const upperQuantileInnerTrace = Object.assign({}, baseQuantileTraceConfig, {
    y: Object.values(sortedQuantiles)[2],
    fill: "tonexty",
    fillcolor: `rgba(${colors[0]},0.4)`,
  });

  const upperQuantileOuterTrace = Object.assign({}, baseQuantileTraceConfig, {
    y: Object.values(sortedQuantiles)[3],
    fill: "tonexty",
    fillcolor: `rgba(${colors[0]},0.2)`,
  });

  const historicalTrace = {
    x: xHistorical,
    y: yHistorical,
    mode: "lines+markers",
    // i18n-tasks-use t('forecast_reports.employees_forecast.historical_employee_count')
    name: I18n.historical_employee_count,
    type: "scatter",
    marker: { color: "rgb(33,41,54)", size: 4 },
    line: { color: "rgb(33,41,54)" },
    hoverinfo: "x+y",
  };

  // Configure the layout
  // i18n-tasks-use t('forecast_reports.employees_forecast.employee_count_plot_title')
  const layout = generatePlotLayout(I18n.employee_count_plot_title, xForecast);

  // Limit x-axis range to the observed values
  layout.xaxis = { range: [xHistorical[0], xForecast[xForecast.length - 1]] };

  deepMergeOptions(layout, commonPlotOptions());

  // Draw a vertical line at today's date
  layout.shapes = [
    {
      type: "line",
      xref: "x",
      yref: "paper",
      x0: new Date(latestRecordDate),
      y0: 0,
      x1: new Date(latestRecordDate),
      y1: 1,
      line: { color: "gray", width: 1, dash: "dot" },
    },
  ];

  // Configure the plot
  const config = generatePlotConfig();

  // Create the plot
  Plotly.newPlot(
    el,
    [
      historicalTrace,
      lowerQuantileOuterTrace,
      lowerQuantileInnerTrace,
      meanTrace,
      upperQuantileInnerTrace,
      upperQuantileOuterTrace,
    ],
    layout,
    config,
  );
}

function ticketCountForecastPlot(forecastedTicketCount, el, I18n) {
  // Destructure the forecastedTicketCount object
  const forecastedInstallTicketCount = forecastedTicketCount.install_tickets;
  const forecastedReturnTicketCount = forecastedTicketCount.return_tickets;
  const forecastedRepairTicketCount = forecastedTicketCount.repair_tickets;

  // Register the current locale
  registerCurrentLocale();

  // Prepare plot data
  const xForecast = generateXForecast(forecastedInstallTicketCount);
  const yInstallForecast = forecastedInstallTicketCount.mean;
  const yReturnForecast = forecastedReturnTicketCount.mean;
  const yRepairForecast = forecastedRepairTicketCount.mean;

  // Prepare plot traces
  const baseTraceConfig = {
    x: xForecast,
    mode: "lines+markers",
    type: "scatter",
    hoverinfo: "x+y",
  };

  const installForecastTrace = Object.assign({}, baseTraceConfig, {
    y: yInstallForecast,
    line: { color: `rgb(${colors[0]})` },
    marker: { color: `rgb(${colors[0]})`, size: 4 },
    // i18n-tasks-use t('forecast_reports.tickets_forecast.forecasted_install_ticket_count')
    name: I18n.forecasted_install_ticket_count,
  });

  const returnForecastTrace = Object.assign({}, baseTraceConfig, {
    y: yReturnForecast,
    line: { color: `rgb(${colors[1]})` },
    marker: { color: `rgb(${colors[1]})`, size: 4 },
    // i18n-tasks-use t('forecast_reports.tickets_forecast.forecasted_return_ticket_count')
    name: I18n.forecasted_return_ticket_count,
  });

  const repairForecastTrace = Object.assign({}, baseTraceConfig, {
    y: yRepairForecast,
    line: { color: `rgb(${colors[2]})` },
    marker: { color: `rgb(${colors[2]})`, size: 4 },
    // i18n-tasks-use t('forecast_reports.tickets_forecast.forecasted_repair_ticket_count')
    name: I18n.forecasted_repair_ticket_count,
  });

  // Configure the plot and layout
  // i18n-tasks-use t('forecast_reports.tickets_forecast.ticket_count_plot_title')
  const layout = generatePlotLayout(I18n.ticket_count_plot_title, xForecast);

  // Limit x-axis range to the observed values and leave space for the legend
  // NOTE: this might not be the most subtle way to do this
  layout.yaxis = {
    range: [
      0,
      Math.max.apply(
        Math,
        yInstallForecast.concat(yReturnForecast).concat(yRepairForecast),
      ) * 1.3,
    ],
  };

  deepMergeOptions(layout, commonPlotOptions());

  const config = generatePlotConfig();

  // Create the plot
  Plotly.newPlot(
    el,
    [installForecastTrace, returnForecastTrace, repairForecastTrace],
    layout,
    config,
  );
}

function devicesAvailableForecastPlot(forecastedDevicesAvailable, el, I18n) {
  // Register the current locale
  registerCurrentLocale();

  // Prepare the plot data
  const xForecast = generateXForecast(forecastedDevicesAvailable);
  const yForecast = forecastedDevicesAvailable.mean;

  // Prepare the plot traces
  const meanTrace = {
    x: xForecast,
    y: yForecast,
    mode: "lines+markers",
    line: { color: `rgb(${colors[0]})` },
    marker: { color: `rgb(${colors[0]})`, size: 4 },
    // i18n-tasks-use t('forecast_reports.devices_forecast.available_device_count')
    name: I18n.available_device_count,
    type: "scatter",
  };

  // Configure the plot and layout
  const layout = generatePlotLayout(
    // i18n-tasks-use t('forecast_reports.devices_forecast.available_device_count_plot_title')
    I18n.available_device_count_plot_title,
    xForecast,
  );

  deepMergeOptions(layout, commonPlotOptions());

  const config = generatePlotConfig();

  // Create the plot
  Plotly.newPlot(el, [meanTrace], layout, config);
}

function sortQuantiles(quantiles) {
  return Object.keys(quantiles)
    .sort()
    .reduce((obj, key) => {
      obj[key] = quantiles[key];
      return obj;
    }, {});
}

function generateStackedAreaErrorPlotData(
  eventTypes,
  colors,
  xForecast,
  forecastReportOutput,
  I18n,
  stackgroup = "one",
) {
  // Initialize the plot data
  const plotData = [];

  // Initialize the accumulated base to start at zero
  let accumulatedBase = new Array(xForecast.length).fill(0);

  // Generate traces for each of the event types
  eventTypes.forEach((eventType, i) => {
    // Prepare plot y-data
    const yForecast = forecastReportOutput[eventType].mean;

    // Ensure sort order of quantiles is ascending
    const sortedQuantiles = sortQuantiles(
      forecastReportOutput[eventType].quantiles,
    );
    const quantileVals = Object.values(sortedQuantiles);

    // Prepare base traces
    const baseTrace = {
      x: xForecast,
      y: yForecast,
      base: accumulatedBase,
      marker: { color: "transparent", line: { color: "transparent" } },
    };

    const baseErrorTrace = Object.assign({}, baseTrace, {
      marker: { color: "transparent", line: { color: "transparent" } },
      type: "bar",
      visible: "legendonly",
      hoverinfo: "skip",
      error_y: {
        type: "data",
        symmetric: false,
        visible: true,
        thickness: 1,
        width: 4,
      },
    });

    // Prepare the mean trace
    const meanTrace = Object.assign({}, baseTrace, {
      marker: {
        color: `rgba(${colors[i % colors.length]}, 0.6)`,
        line: {
          color: `rgb(${colors[i % colors.length]})`,
          width: 1.5,
        },
      },
      name: I18n[`forecasted_${eventType.replaceAll(".", "_")}`],
      hoverinfo: "x+y",
      stackgroup,
    });

    // Prepare the mean trace 95%-CI
    const meanTraceError95CI = Object.assign({}, baseErrorTrace, {
      // i18n-tasks-use t('forecast_reports.customer_forecast.error_95_ci')
      name: I18n.error_95_ci,
      error_y: Object.assign({}, baseErrorTrace.error_y, {
        array: yForecast.map((y, i) => quantileVals[3][i] - y),
        arrayminus: yForecast.map((y, i) => y - quantileVals[0][i]),
        color: `rgb(${darkenColor(colors[i % colors.length], 0.6)})`,
      }),
    });

    // Add the traces to the plot data
    plotData.push(meanTraceError95CI, meanTrace);

    // Update the accumulated base for the next eventType
    accumulatedBase = accumulatedBase.map((base, i) => base + yForecast[i]);
  });

  return plotData;
}

function devicesByDestinationForecastPlot(forecastReportOutput, el, I18n) {
  // Register the current locale
  registerCurrentLocale();

  // Prepare x-axis labels
  const xForecast = generateXForecast(forecastReportOutput["devices.count"]);

  // Generate the plot data for the stacked bar chart with error bars
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_count_available')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_count_employees')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_count_locations')
  const plotData = generateStackedAreaErrorPlotData(
    [
      "devices.count.locations",
      "devices.count.employees",
      "devices.count.available",
    ],
    colors,
    xForecast,
    forecastReportOutput,
    I18n,
  );

  // Configure the layout with a range slider
  const layout = generatePlotLayout(
    // i18n-tasks-use t('forecast_reports.customer_forecast.devices_by_destination_forecast_plot_title')
    I18n.devices_by_destination_forecast_plot_title,
    xForecast,
  );

  // Enable toggling the confidence interval traces
  // NOTE: we disabled toggling the main traces using css to avoid breaking the layout
  layout.legend.itemclick = true;

  // Limit y-axis range to the maximum value of the 95%-CI to display legend properly
  layout.yaxis = {
    range: [
      0,
      Math.max.apply(
        null,
        Object.values(forecastReportOutput["devices.count"].quantiles)[3],
      ),
    ],
  };
  deepMergeOptions(layout, commonPlotOptions());
  const config = generatePlotConfig();

  // Create the plot with the range slider
  Plotly.newPlot(el, plotData, layout, config);
}

function generateLineErrorPlotData(
  eventTypes,
  colors,
  xForecast,
  forecastReportOutput,
  I18n,
) {
  // Initialize the plot data
  const plotData = [];

  // NOTE: we sort descending by the mean sum to format nicely
  const sortedEventTypes = eventTypes.sort((a, b) => {
    return forecastReportOutput[b].sum.mean - forecastReportOutput[a].sum.mean;
  });

  // Generate traces for each of the event types
  sortedEventTypes.forEach((eventType, i) => {
    // Prepare plot y-data
    const yForecast = forecastReportOutput[eventType].mean;

    // Ensure sort order of quantiles is ascending
    const sortedQuantiles = sortQuantiles(
      forecastReportOutput[eventType].quantiles,
    );
    const quantileVals = Object.values(sortedQuantiles);

    // Prepare base traces
    const baseTrace = {
      x: xForecast,
      y: yForecast,
      marker: { color: "transparent", line: { color: "transparent" } },
    };

    const baseErrorTrace = Object.assign({}, baseTrace, {
      marker: { color: "transparent", line: { color: "transparent" } },
      type: "bar",
      visible: "legendonly",
      hoverinfo: "skip",
      error_y: {
        type: "data",
        symmetric: false,
        visible: true,
        thickness: 1,
        width: 4,
      },
    });

    // Prepare the mean trace
    const meanTrace = Object.assign({}, baseTrace, {
      marker: {
        color: `rgba(${colors[i % colors.length]}, 0.6)`,
        line: {
          color: `rgb(${colors[i % colors.length]})`,
          width: 1.5,
        },
      },
      name: I18n[`forecasted_${eventType.replaceAll(".", "_")}`],
      hoverinfo: "x+y",
    });

    // Prepare the mean trace 95%-CI
    const meanTraceError95CI = Object.assign({}, baseErrorTrace, {
      // i18n-tasks-use t('forecast_reports.customer_forecast.error_95_ci')
      name: I18n.error_95_ci,
      error_y: Object.assign({}, baseErrorTrace.error_y, {
        array: yForecast.map((y, i) => quantileVals[3][i] - y),
        arrayminus: yForecast.map((y, i) => y - quantileVals[0][i]),
        color: `rgb(${colors[i % colors.length]})`,
      }),
    });

    // Add the traces to the plot data
    plotData.push(meanTrace, meanTraceError95CI);
  });

  return plotData;
}

function devicesInflowByTypeForecastPlot(forecastReportOutput, el, I18n) {
  // Register the current locale
  registerCurrentLocale();

  // Generate x-axis labels by transforming the forecast array to months into the future
  const xForecast = generateXForecast(forecastReportOutput["devices.count"]);

  // Filter the forecast report for the devices by type
  const deviceInflowData = Object.keys(forecastReportOutput)
    .filter((key) => key.indexOf("devices.in.") === 0)
    .reduce((acc, key) => {
      acc[key] = forecastReportOutput[key];
      return acc;
    }, {});

  // Generate the plot data for the stacked bar chart with error bars
  // NOTE: below indicates that all of the known device types translations are included
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_audiovisual')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_component')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_computer')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_display')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_infrastructure')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_laptop')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_other')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_peripheral')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_printer')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_smartphone')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_in_tablet')
  const plotData = generateLineErrorPlotData(
    Object.keys(deviceInflowData),
    colors,
    xForecast,
    forecastReportOutput,
    I18n,
  );

  // Configure the layout with a range slider
  const layout = generatePlotLayout(
    // i18n-tasks-use t('forecast_reports.customer_forecast.devices_inflow_by_type_forecast_plot_title')
    I18n.devices_inflow_by_type_forecast_plot_title,
    xForecast,
  );

  // Enable toggling the confidence interval traces
  // NOTE: we disabled toggling the main traces using css
  layout.legend.itemclick = true;

  deepMergeOptions(layout, commonPlotOptions());

  // Generate the plot configuration
  const config = generatePlotConfig();

  // Create the plot with the range slider
  Plotly.newPlot(el, plotData, layout, config);
}

function devicesOutflowByTypeForecastPlot(forecastReportOutput, el, I18n) {
  // Register the current locale
  registerCurrentLocale();

  // Generate x-axis labels by transforming the forecast array to months into the future
  const xForecast = generateXForecast(forecastReportOutput["devices.count"]);

  // Filter the forecast report for the devices by type
  const deviceOutflowData = Object.keys(forecastReportOutput)
    .filter((key) => key.indexOf("devices.out.") === 0)
    .reduce((acc, key) => {
      acc[key] = forecastReportOutput[key];
      return acc;
    }, {});

  // Generate the plot data for the stacked bar chart with error bars
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_audiovisual')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_component')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_computer')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_display')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_infrastructure')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_laptop')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_other')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_peripheral')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_printer')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_smartphone')
  // i18n-tasks-use t('forecast_reports.customer_forecast.forecasted_devices_out_tablet')
  const plotData = generateLineErrorPlotData(
    Object.keys(deviceOutflowData),
    colors,
    xForecast,
    forecastReportOutput,
    I18n,
  );

  // Configure the layout with a range slider
  const layout = generatePlotLayout(
    // i18n-tasks-use t('forecast_reports.customer_forecast.devices_outflow_by_type_forecast_plot_title')
    I18n.devices_outflow_by_type_forecast_plot_title,
    xForecast,
  );

  // Enable toggling the confidence interval traces
  // NOTE: we disabled toggling the main traces using css
  layout.legend.itemclick = true;

  deepMergeOptions(layout, commonPlotOptions());

  // Generate the plot configuration
  const config = generatePlotConfig();

  // Create the plot with the range slider
  Plotly.newPlot(el, plotData, layout, config);
}

window.employeeCountForecastPlot = employeeCountForecastPlot;
window.ticketCountForecastPlot = ticketCountForecastPlot;
window.devicesAvailableForecastPlot = devicesAvailableForecastPlot;
window.devicesByDestinationForecastPlot = devicesByDestinationForecastPlot;
window.devicesInflowByTypeForecastPlot = devicesInflowByTypeForecastPlot;
window.devicesOutflowByTypeForecastPlot = devicesOutflowByTypeForecastPlot;
