import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  Alpine.data("analysisChecker", () => ({
    loading: true,
    devicesHtml: "",
    maxAttempts: 5,
    attemptCount: 0,

    init() {
      this.checkAnalysisStatus();
    },

    async checkAnalysisStatus() {
      console.log("Checking analysis status...");
      this.attemptCount++;

      try {
        const response = await fetch(window.location.href + "/status");
        const data = await response.json();

        if (data.analysis_complete || this.attemptCount >= this.maxAttempts) {
          this.loading = false;
          this.devicesHtml = data.devices_html;
          if (!data.analysis_complete) {
            throw new Error(
              "Max attempts reached without completing analysis.",
            );
          }
        } else {
          const delay = Math.pow(2, this.attemptCount) * 1000; // Exponential backoff
          setTimeout(() => this.checkAnalysisStatus(), delay);
        }
      } catch (error) {
        console.error("Error checking analysis status:", error);
      }
    },
  }));
});
