import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  Alpine.data("appTheme", () => ({
    theme: "system",
    darkMode: false, // for use in Alpine components
    dropdownOpen: false,

    init() {
      // Check for saved theme in localStorage
      const savedTheme = localStorage.getItem("theme");

      if (savedTheme) {
        this.theme = savedTheme;
      } else {
        this.theme = "system";
      }

      this.apply();

      // Listen for changes to the system's color scheme preference
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", () => {
          this.apply();
        });
    },

    toggle(theme) {
      this.theme = theme;
      if (theme === "system") {
        localStorage.removeItem("theme");
      } else {
        localStorage.setItem("theme", theme);
      }

      this.apply();
      this.dropdownOpen = false;
    },

    apply() {
      const isDarkMode =
        this.theme === "dark" ||
        (this.theme === "system" &&
          window.matchMedia("(prefers-color-scheme: dark)").matches);

      document.documentElement.classList.toggle("dark", isDarkMode);
      this.darkMode = isDarkMode;
    },
  }));
});
